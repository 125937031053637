<template>
  <div class="">
    <div id="preloader" v-if="!ready">
      <div data-loader="circle-side"></div>
    </div>
    <!-- /Preload -->

    <div id="loader_form">
      <div data-loader="circle-side-2"></div>
    </div>
    <!-- /loader_form -->

    <nav>
      <ul class="cd-primary-nav">
        <li>
          <a href="https://www.vat4u.com" class="animated_link">Home</a>
        </li>
        <li>
          <a href="https://www.vat4u.com" class="animated_link"
            >About Us</a
          >
        </li>
        <li>
          <a href="https://www.vat4u.com" class="animated_link"
            >Contact Us</a
          >
        </li>
      </ul>
    </nav>
    <!-- /menu -->

    <div class="container-fluid full-height">
      <div class="row row-height">
        <div class="col-lg-6 content-left no-gutters">
          <div class="content-left-wrapper">
            <!-- <a href="index.html" id="logo"
              ><img src="img/logo.png" alt="" width="49" height="35"
            /></a> -->
            <div id="social">
              <ul>
                <!--
                <li>
                  <a href="#0"><i class="icon-google"></i></a>
                </li>
                -->
                <li>
                  <a href="https://in.linkedin.com/company/vat4u"
                    ><i class="icon-linkedin"></i
                  ></a>
                </li>
              </ul>
            </div>
            <!-- /social -->
            <div>
              <figure>
                <img src="/img/demoexperts-logo.png" alt="" class="img-fluid" />
              </figure>
              <h2>IOSS Registration form</h2>
              <p>
                A "Know Your Customer procedure" is part of the registration
                process. Based on the information provided by you and after
                identification of the company’s representative, a customer due
                diligence will be carried out. You will receive the agreements
                required for our services upon acceptance. On receipt of your
                signed agreement(s) and payment of the required registration
                fee, Less Grey will register you ans a non-EU based seller for
                the iOSS scheme with the Dutch Tax Authorities.
              </p>
              <a @click="startNow" class="btn_1 rounded">Start Now</a>
              <a href="#start" class="btn_1 rounded mobile_btn">Start Now!</a>
            </div>
            <div class="copy">
              <a
                class="btn border-none text-light"
                href="https://vat4u.com"
                target="_blank"
                >© 2021 The Demo VAT Experts</a
              >

              <a
                class="btn border-none text-light"
                href="https://vat4u.com"
                target="_blank"
                >Powered by VAT4U</a
              >
            </div>
          </div>
          <!-- /content-left-wrapper -->
        </div>
        <!-- /content-left -->

        <div class="col-lg-6 content-right no-gutters" id="start">
          <div id="wizard_container">
            <div id="top-wizard">
              <div id="progressbar"></div>
            </div>
            <!-- /top-wizard -->
            <form id="wrapped" method="POST" enctype="multipart/form-data">
              <input id="website" name="website" type="text" value="" />
              <!-- Leave for security protection, read docs for details -->
              <div id="middle-wizard">
                <h3 class="main_question">
                  <strong>
                    <span>{{ currentStep }}/{{ totalSteps - 1 }}</span>

                    <a-popover title="" v-if="currentStep == totalSteps - 1">
                      <template slot="content">
                        <div>Status of this registration.</div>
                      </template>
                      <a class="float-right">
                        <span
                          class="badge badge-pill text-light"
                          :class="accountStatusClass"
                          >{{ accountStatusCode }}
                        </span>
                      </a>
                    </a-popover>
                  </strong>

                  {{ this.steps[currentStep].title }}
                </h3>

                <div
                  v-if="
                    currentStep == totalSteps - 1 &&
                    accountStatusCode == 'INCOMPLETE'
                  "
                  class="alert alert-warning"
                  role="alert"
                >
                  <p>
                    <strong>Please review the following fields!</strong> You can
                    reopen this form by clicking at the link in the registration
                    email we've sent you.
                  </p>
                  <ul class="list-group list-group-flush">
                    <li v-for="error in accountErrors" :key="error.field">
                      {{ error.error }}
                    </li>
                  </ul>
                </div>

                <!-- <div v-show="currentStep == 0" class="step">
                  <a-spin :spinning="savingStep" tip="Saving...">
                    <Step0 />
                  </a-spin>
                </div> -->
                <!-- /step-->
                <div v-show="currentStep == 1" class="step">
                  <a-spin :spinning="savingStep" tip="Saving...">
                    <Step1 />
                    <p
                      class="text-danger"
                      v-if="isEmailInvalid && userInteractionStarted"
                      >Please type a valid email</p
                    >
                    <p
                      class="text-danger"
                      v-if="isPhoneInvalid && userInteractionStarted"
                      >Please type a valid phone number. Use only numeric characters and + inside the phone number field</p
                    >
                    <p
                      class="text-danger"
                      v-if="responseError && userInteractionStarted"
                    >
                      {{ responseError }}
                    </p>
                  </a-spin>
                </div>
                <div v-show="currentStep == 2" class="step">
                  <a-spin :spinning="savingStep" tip="Saving...">
                    <Step2 />
                  </a-spin>
                </div>
                <!-- /step-->
                <div v-show="currentStep == 3" class="step">
                  <a-spin :spinning="savingStep" tip="Saving...">
                    <Step3 />
                  </a-spin>
                </div>
                <!-- /step-->
                <div v-show="currentStep == 4" class="step">
                  <a-spin :spinning="savingStep" tip="Saving...">
                    <Step4 />
                  </a-spin>
                </div>
                <!-- /step-->
                <div v-show="currentStep == 5" class="step">
                  <a-spin :spinning="savingStep" tip="Saving...">
                    <Step5 />
                  </a-spin>
                </div>
                <div v-show="currentStep == 6" class="step">
                  <a-spin :spinning="savingStep" tip="Saving...">
                    <Step6 />
                  </a-spin>
                </div>
                <div v-show="currentStep == 7" class="step">
                  <a-spin :spinning="savingStep" tip="Saving...">
                    <Step7 />
                  </a-spin>
                </div>
                <div v-show="currentStep == 8" class="step">
                  <a-spin :spinning="savingStep" tip="Saving...">
                    <Step8 />
                  </a-spin>
                </div>
                <div v-show="currentStep == 9" class="step">
                  <a-spin :spinning="savingStep" tip="Saving...">
                    <Step9 />
                  </a-spin>
                </div>
                <div v-show="currentStep == 10" class="step">
                  <a-spin :spinning="savingStep" tip="Saving...">
                    <Step10 />
                  </a-spin>
                </div>

                <!-- /step-->

                <p class="text-danger" v-if="errorVisible">
                  Please make sure to confirm data and ackonwledge that you have
                  read our conditions of registration.
                </p>
              </div>
              <!-- /middle-wizard -->
              <div id="bottom-wizard" class="pb-3">
                <a-space :space="8">
                  <button
                    @click="moveBack"
                    v-if="currentStep != 1 && currentStep != totalSteps - 1"
                    type="button"
                    name="backward"
                    class="backward"
                  >
                    Prev
                  </button>
                  <button
                    v-show="currentStep != totalSteps - 1"
                    @click="moveForward"
                    type="button"
                    name="forward"
                    class="forward"
                  >
                    {{
                      currentStep == 1
                        ? "Register & Continue"
                        : currentStep == totalSteps - 2
                        ? "Submit"
                        : "Next"
                    }}
                  </button>
                </a-space>
              </div>
              <!-- /bottom-wizard -->
            </form>
          </div>
          <!-- /Wizard container -->
        </div>
        <!-- /content-right-->
      </div>
      <!-- /row-->
    </div>
    <!-- /container-fluid -->

    <div class="cd-overlay-nav">
      <span></span>
    </div>
    <!-- /cd-overlay-nav -->

    <div class="cd-overlay-content">
      <span></span>
    </div>
    <!-- /cd-overlay-content -->

    <a class="cd-nav-trigger" @click="toggleNav"
      >Menu<span class="cd-icon"></span
    ></a>
    <!-- /menu button -->

    <!-- Modal terms -->
    <div
      class="modal fade"
      id="terms-txt"
      tabindex="-1"
      role="dialog"
      aria-labelledby="termsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="termsLabel">Terms and conditions</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body">
            <p>
              Lorem ipsum dolor sit amet, in porro albucius qui, in
              <strong>nec quod novum accumsan</strong>, mei ludus tamquam
              dolores id. No sit debitis meliore postulant, per ex prompta
              alterum sanctus, pro ne quod dicunt sensibus.
            </p>
            <p>
              Lorem ipsum dolor sit amet, in porro albucius qui, in nec quod
              novum accumsan, mei ludus tamquam dolores id. No sit debitis
              meliore postulant, per ex prompta alterum sanctus, pro ne quod
              dicunt sensibus. Lorem ipsum dolor sit amet,
              <strong>in porro albucius qui</strong>, in nec quod novum
              accumsan, mei ludus tamquam dolores id. No sit debitis meliore
              postulant, per ex prompta alterum sanctus, pro ne quod dicunt
              sensibus.
            </p>
            <p>
              Lorem ipsum dolor sit amet, in porro albucius qui, in nec quod
              novum accumsan, mei ludus tamquam dolores id. No sit debitis
              meliore postulant, per ex prompta alterum sanctus, pro ne quod
              dicunt sensibus.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn_1" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Velocity from "velocity-animate";

import { emailIsValid, phoneIsValid } from "@/services/utilities";
import { createComputed } from "@/services/utilities";

import { mapGetters, mapActions } from "vuex";
import {
  Steps,
  Select,
  Space,
  Spin,
  Popover,
  Upload,
  Button,
  Icon,
} from "ant-design-vue";

Vue.use(Steps);
Vue.use(Select);
Vue.use(Space);
Vue.use(Spin);
Vue.use(Popover);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Upload);

// import Step0 from "./steps/Step0";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import Step6 from "./steps/Step6";
import Step7 from "./steps/Step7";
import Step8 from "./steps/Step8";
import Step9 from "./steps/Step9";
import Step10 from "./steps/Step10";

export default {
  name: "OnboardingForm",
  components: {
    // Step0,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9,
    Step10,
  },
  props: {},
  data: function () {
    return {
      userInteractionStarted: false,
      ready: false,
      savingStep: false,
      navOpen: false,
      errorVisible: false,
      currentStep: 1, // we will reset to 0 when we introduce Into step
      accountStatus: {
        status_code: null,
        errors: [],
      },
      responseError: null,
      steps: [
        {
          key: "0",
          title: "Registration info",
        },
        {
          key: "1",
          title: "Your personal information",
        },
        {
          key: "2",
          title: "Company Information",
        },
        {
          key: "3",
          title: "Website and eshop platforms",
        },
        {
          key: "4",
          title: "Your TAX information",
        },
        {
          key: "5",
          title: "OSS Registrations",
        },
        {
          key: "6",
          title: "Operations and forecast",
        },
        {
          key: "7",
          title: "Company Bank Account Details",
        },
        {
          key: "8",
          title: "Company legal representative data",
        },
        {
          key: "submit",
          title: "Terms & Conditions",
        },
        {
          key: "final",
          title: "Thank you!",
        },
      ],
    };
  },
  methods: {
    startNow() {
      document.getElementById("firstFormField").focus();
    },
    toggleNav() {
      console.log("Toggling navigation");
      var navigation = document.getElementsByClassName("cd-primary-nav")[0],
        toggleNav = document.getElementsByClassName("cd-nav-trigger")[0];

      console.log(toggleNav);

      if (!this.navOpen) {
        //it means navigation is not visible yet - open it and animate navigation layer
        toggleNav.classList.add("close-nav");

        Velocity(
          document.querySelectorAll(".cd-primary-nav span"),
          {
            translateZ: 0,
            scaleX: 1,
            scaleY: 1,
          },
          500,
          "easeInCubic",
          function () {
            navigation.addClass("fade-in");
          }
        );

        Velocity(
          document.querySelectorAll(".cd-overlay-nav span"),
          {
            translateZ: 0,
            scaleX: 1,
            scaleY: 1,
          },
          500,
          "easeInCubic",
          function () {
            navigation.classList.add("fade-in");
          }
        );
      } else {
        //navigation is open - close it and remove navigation layer
        toggleNav.classList.remove("close-nav");

        Velocity(
          document.querySelectorAll(".cd-overlay-content span"),
          {
            translateZ: 0,
            scaleX: 1,
            scaleY: 1,
          },
          500,
          "easeInCubic",
          function () {
            navigation.classList.remove("fade-in");
            console.log("Removing span");
            Velocity(
              document.querySelectorAll(".cd-overlay-content span"),
              {
                translateZ: 0,
                scaleX: 0,
                scaleY: 0,
              },
              0
            );
            Velocity(
              document.querySelectorAll(".cd-overlay-nav span"),
              {
                translateZ: 0,
                scaleX: 0,
                scaleY: 0,
              },
              0
            );
          }
        );

        Velocity(
          document.querySelectorAll(".cd-primary-nav span"),
          {
            translateZ: 0,
            scaleX: 0,
            scaleY: 0,
          },
          0,
          "easeInCubic",
          function () {
            navigation.classList.remove("fade-in");
          }
        );
      }

      this.navOpen = !this.navOpen;
    },
    moveForward() {
      if (this.currentStep == this.totalSteps - 2) {
        if (!this.userConfirmation || !this.userAcknowledgement) {
          this.errorVisible = true;
        } else {
          this.autosave(true);
          this.errorVisible = false;
        }
      } else {
        this.autosave(true);
      }
    },
    moveBack() {
      this.currentStep--;
    },

    autosave(autoMove) {
      if (this.currentStep == 1) {
        this.userInteractionStarted = true;
        // validate email
        if (this.isEmailInvalid || this.isPhoneInvalid) {
          return;
        }
      }

      var t = this;
      t.savingStep = true;
      t.step = t.steps[t.currentStep].key;
      this.$store
        .dispatch("form/autosave")
        .then((data) => {
          if (data.status) {
            this.accountStatus = data.status;
          }

          if (autoMove) {
            t.currentStep++;
          }
        })
        .catch((error) => {
          console.error(error);
          this.responseError = error.response.data.error
        })
        .finally(() => {
          t.savingStep = false;
        });
    },

    layerInit() {
      var diameterValue =
        Math.sqrt(
          Math.pow(window.innerHeight, 2) + Math.pow(window.innerWidth, 2)
        ) * 2;

      Velocity(
        document.querySelectorAll(".cd-overlay-nav span"),
        {
          scaleX: 0,
          scaleY: 0,
          translateZ: 0,
        },
        50
      );

      Velocity(
        document.querySelectorAll(".cd-overlay-nav span"),
        {
          height: diameterValue + "px",
          width: diameterValue + "px",
          top: -(diameterValue / 2) + "px",
          left: -(diameterValue / 2) + "px",
        },
        0
      );

      Velocity(
        document.querySelectorAll(".cd-overlay-content span"),
        {
          scaleX: 0,
          scaleY: 0,
          translateZ: 0,
        },
        50
      );
      Velocity(
        document.querySelectorAll(".cd-overlay-content span"),
        {
          height: diameterValue + "px",
          width: diameterValue + "px",
          top: -(diameterValue / 2) + "px",
          left: -(diameterValue / 2) + "px",
        },
        0
      );
    },
    ...mapActions("system", ["fetchCountries"]),
  },
  computed: {
    accountStatusCode() {
      return this.accountStatus.status_code == "INCOMPLETE"
        ? "INCOMPLETE"
        : "UNDER REVIEW";
    },
    accountStatusClass() {
      return this.accountStatusCode == "INCOMPLETE"
        ? "bg-warning"
        : "bg-secondary";
    },
    accountErrors() {
      return this.accountStatus.errors;
    },
    step: createComputed("form", "form.step"),
    isEmailInvalid() {
      return !emailIsValid(this.userEmail)
    },
    isPhoneInvalid(){
      return !phoneIsValid(this.userPhone)
    },
    totalSteps() {
      return this.steps.length;
    },
    ...mapGetters("system", ["countries"]),
    ...mapGetters("form", [
      "userEmail",
      "userPhone",
      "userConfirmation",
      "userAcknowledgement",
    ]),
  },
  mounted() {
    this.ready = true;
    var token = this.$route.query.token;
    if (token) {
      this.$store.dispatch("form/load", token);
    }

    this.layerInit();
    this.fetchCountries();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.no-gutters {
  padding-right: 0;
  padding-left: 0;
}
</style>

<style>
.form-group.required::after {
  content: " *";
  color: red;
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 0px;
}
.input-group.required::after {
  content: " *";
  color: red;
  display: inline-block;
  position: absolute;
  right: 45px;
  top: 0px;
}
</style>
